<template>
  <el-dialog
    class="my-dialog"
    :title="title"
    width="900px"
    :close-on-click-modal="false"
    :visible.sync="closeFlag"
    :before-close="closeModel"
    append-to-body
    destroy-on-close
    :show-close="!loading"
    center
  >
    <div class="forms-form" v-loading="loading" element-loading-text="保存中" >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        size="middle"
        label-width="150px"
      >
        <div>
          <el-row>
            <el-col :span="24">
              <el-form-item label="产品种类：" prop="productTypeName">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.productTypeName }}</p>
                <el-input :disabled="true" v-else class="my-input-btn" v-model="ruleForm.productTypeName" placeholder="请选择产品种类">
                  <el-button v-if="editType == 'add'" slot="append" @click="chooseType" type="primary">选择</el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="产品名称：" prop="productName">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.productName }}</p>
                <el-input clearable v-else v-model="ruleForm.productName" placeholder="请输入产品名称" maxlength=32 show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item :label="tempType == '01' ? '产品编号（条码）：' : '供应商产品编号（条码）：'" prop="productCode">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.productCode }}</p>
                <el-input clearable v-else :disabled="true" v-model="ruleForm.productCode" :placeholder="tempType == '01' ? '请输入产品编号（条码）' : '请输入供应商产品编号（条码）'" maxlength=32 show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="产品图片：" prop="productUrl" class="my-upload-files">
                <el-image 
                  v-for="(item, index) in ruleForm.productUrl"
                  :key="index"
                  style="width: 100px; height: 100px"
                  :src="item" 
                  :preview-src-list="ruleForm.productUrl">
                </el-image>
                <div v-if="editType == 'look' && !ruleForm.productUrl.length" class="no-data">无图片</div>
                <div v-if="editType != 'look'" @click="addPics" class="picture-card-plus"><i class="el-icon-plus"></i></div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="产品介绍：" prop="productIntroduce">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.productIntroduce }}</p>
                <el-input clearable v-else v-model="ruleForm.productIntroduce" placeholder="请输入产品介绍" maxlength=600 show-word-limit type="textarea" :rows="4"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="editType != 'add'">
            <el-col :span="24">
              <el-form-item label="状态：" prop="status">
                <el-radio-group v-model="ruleForm.status" :disabled="editType == 'look'">
                  <el-radio label="0">启用</el-radio>
                  <el-radio label="1">停用</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>

          <el-collapse v-model="ruleForm.collapse" class="my-collapse">
            <el-collapse-item title="更多信息" name="1">
              <div class="line-color"></div>
              <el-row v-if="tempType == '01'">
                <el-col :span="24">
                  <el-form-item label="产品认证：" prop="unitQualificationFile">
                    <el-select multiple @change="setunitQualification" v-model="ruleForm.unitQualificationFile" class="full-width" clearable :placeholder="editType == 'look' ? '' : '请选择产品认证'" :disabled="editType == 'look'">
                      <el-option
                        v-for="(item, index) in selectObj.unitQualification"
                        :key="index"
                        :label="item.unitQualificationFileName"
                        :value="item.unitQualificationFileId">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="品牌名称：" prop="brandName">
                    <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.brandName }}</p>
                    <el-input clearable v-else v-model="ruleForm.brandName" placeholder="请输入品牌名称" maxlength=32 show-word-limit></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- <el-row>
                <el-col :span="24">
                  <el-form-item label="年产量：" prop="practicaWeight">
                    <p v-if="editType == 'look'" class="view-cont">{{ (ruleForm.yearlyOutput || '') + (ruleForm.yearlyOutputUnit || '')}}</p>
                    <el-input clearable v-else v-model="ruleForm.yearlyOutput" placeholder="请输入年产量" maxlength=14 show-word-limit class="input-with-select">
                      <el-select v-model="ruleForm.yearlyOutputUnit" slot="append" placeholder="请选择">
                        <el-option
                          v-for="(item, index) in selectObj.weight"
                          :key="index"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row> -->
              <el-row>
                <el-col :span="24">
                  <el-form-item :label="tempType == '01' ? '年产量：' : '年预收购量：'" prop="yearlyOutput">
                    <p v-if="editType == 'look'" class="view-cont">{{ (ruleForm.yearlyOutput || '') + (ruleForm.yearlyOutputUnit || '')}}</p>
                    <el-input clearable v-else v-model="ruleForm.yearlyOutput" :placeholder="tempType == '01' ? '请输入年产量' : '请输入年预收购量'" maxlength=14 show-word-limit class="input-with-select">
                      <el-select v-model="ruleForm.yearlyOutputUnit" slot="append" placeholder="请选择">
                        <el-option
                          v-for="(item, index) in selectObj.weight"
                          :key="index"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="生产周期：" prop="productionCycle">
                    <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.productionCycle }}</p>
                    <el-input clearable v-else v-model="ruleForm.productionCycle" placeholder="请输入生产周期" maxlength=32 show-word-limit></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="上市期：" prop="listingDate">
                    <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.listingDate }}</p>
                    <el-date-picker 
                      v-else
                      :disabled="editType == 'look'"
                      class="date-width"
                      v-model="ruleForm.listingDate"
                      type="date"
                      value-format="yyyy-MM-dd"
                      placeholder="请选择上市期">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row v-if="tempType == '01'">
                <el-col :span="24">
                  <el-form-item label="主要销售地：" prop="salesArea">
                    <el-select v-model="ruleForm.salesArea" class="full-width" clearable :placeholder="editType == 'look' ? '' : '请选择主要销售地'" :disabled="editType == 'look'">
                      <el-option
                        v-for="(item, index) in selectObj.salesArea"
                        :key="index"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item :label="tempType == '01' ? '当前预计销售价：' : '当前预计收购价：'" prop="anticipatedPrice">
                    <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.anticipatedPrice }}</p>
                    <el-input clearable v-else v-model="ruleForm.anticipatedPrice" :placeholder="tempType == '01' ? '请输入当前预计销售价' : '请输入当前预计收购价'" maxlength=14 show-word-limit></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row v-if="tempType == '01'">
                <el-col :span="24">
                  <el-form-item label="销售网址：" prop="salesUrl">
                    <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.salesUrl }}</p>
                    <el-input clearable v-else v-model="ruleForm.salesUrl" placeholder="请输入销售网址" maxlength=256 show-word-limit type="textarea" :rows="4"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-collapse-item>
          </el-collapse>
        </div>
      </el-form>
    </div>
    <div class="forms-btns" slot="footer">
      <el-button type="primary" v-if="editType != 'look'" :loading="loading" size="small" @click="submitForm('ruleForm')">保存</el-button>
      <el-button @click="closeModel" :disabled="loading" size="small"> {{ editType == 'look' ? '关闭' : '取消' }}</el-button>
    </div>
    <pics ref="pics" dictCode="103001" :businessCode="ruleForm.productId" :imgSize="0.5" propName="productUrl" showcrop storeTableName="productFileTable" @getpics="getpics"></pics>
    <productType ref="getproducttype" @getproducttype="getproducttype"></productType>
  </el-dialog>
</template>
<script>
import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
import productType from './productType.vue'
import pics from './pics.vue'
export default {
  name: "productEdit",
  components: {
    pics,
    productType
  },
  props:{
    fromType: {
      type: String,
      default: ''
    },
    tempType: {
      type: String,
      default: '02' //01为产品 02为供应商
    },
  },
  data() {
    return {   
      rules: {
        productTypeName: [{ required: true,  trigger: "change", message: '请选择产品种类'}],
        productName: [{ required: true,  trigger: "change", message: '请输入产品名称'}],
        salesUrl: [{ required: false, trigger: "change", 
          validator: (rule, val, callback) => {
            if(val && !baseValidate.url(val)){
              callback(new Error("请输入正确的网址"))
            }else{
              callback()
            }
          }
        }],
        yearlyOutput: [{ required: false, trigger: "change", 
          validator: (rule, val, callback) => {
            if(val && !baseValidate.number(val)){
              callback(new Error("请输入数字"))
            }else{
              callback()
            }
          }
        }],
        anticipatedPrice: [{ required: false, trigger: "change", 
          validator: (rule, val, callback) => {
            if(val && !baseValidate.number(val)){
              callback(new Error("请输入数字"))
            }else{
              callback()
            }
          }
        }],
      },
      closeFlag: false,
      loading: false,
      title: '',
      editType: '',
      ruleForm: {},
      selectObj: {
        unitQualification: [],
        salesArea: CONSTPARAMS.salesArea,
        weight: CONSTPARAMS.weight,
      },
    };
  },
  created() {
    this.getSelectData()
	},
  methods: {
    setunitQualification(val){
      let datas = this.selectObj.unitQualification || [],
        names = []
      val.forEach(item => {
        datas.forEach(cur => {
          if(cur.unitQualificationFileId == item) names.push(cur.unitQualificationFileName)
        })
      })
      console.log(val)
      this.ruleForm.productAuth.unitQualificationFileName = names.join(',')
      this.ruleForm.productAuth.unitQualificationFileId = val.join(',')
    },
    async getSelectData(){
      let data = await utils.getUrlDatas('commonunitqualificationfilelist', {bodyId: utils.getBodyId()})
      this.selectObj.unitQualification = data || []
    },
    chooseType(){
      this.$refs.getproducttype.initData()
    },
    getproducttype(data){
      this.ruleForm.productTypeName = data.productTypeName || ''
      this.ruleForm.productName = data.productTypeName || ''
      this.ruleForm.productTypeId = data.productTypeId || ''
    },
    addPics(){
      this.$refs.pics.initData(this.ruleForm.productUrl)
    },
    getpics(data){
      this.ruleForm[data.propName] = data.pics
      this.ruleForm[data.storeTableName] = data.tableName
      this.$refs.ruleForm.validateField(data.propName);
    },
    initData(title, editType, ruleForm){
      this.title = title
      this.editType = editType
      this.ruleForm = ruleForm
      this.closeFlag = true
      this.resetForm()
      editType == 'add' && this.initAdd()
      editType == 'edit' && this.initEdit()
      editType == 'look' && this.initLook()
    },
    initAdd(){
    },
    initEdit(){
    },
    initLook(){
      this.initEdit()
    },
    submitForm(formName) {
      let _this = this,
        editType = this.editType;
      let mesInfo = {
        add: "新增成功！",
        edit: "修改成功！"
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let ways = editType == 'add' ? 'commonproductsave' : 'commonproductupdate',
            target = {
            ..._this.ruleForm,
            bodyId: utils.getBodyId(),
            productUrl: _this.ruleForm.productUrl?.join(',') || '',
          }
          delete target.unitQualificationFile
          delete target.collapse
          _this.loading = true
          request[ways](target).then((res) => {
            if(res.code == 200){
              _this.message(mesInfo[editType], "success")
              _this.closeModel()
              _this.$emit("getData", editType == 'add' ? true : false)
            }else{
              _this.message(res.msg || '', "error")
            }
            _this.loading = false
          }).catch(e => {
            _this.loading = false
          })
        } else {
          _this.message('请先完善信息！', "warning")
          return false
        }
      });
    },
    closeModel() {
      this.closeFlag = false
      // this.$emit("closeModel", false);
    },
    resetForm(formName = 'ruleForm') {
      this.$refs[formName]?.resetFields();
    }
  },
  watch: {
    // 'ruleForm.smpOrgid': {
    //   handler(val){
    //     this.ruleForm.smpOrgid = val
    //     this.queryData()
    //   },
    //   deep: true
    // }
  }
};
</script>

<style lang="scss" scoped>
  .my-dialog ::v-deep .el-dialog {
    margin-top: 5vh !important;
  }
  .my-collapse.el-collapse{
    width: 90%;
    margin-left: 10%;
  }
  .line-color{
    height: 1px;
    background-color: #DCDFE6;
    margin-bottom: 25px;
  }
  .my-collapse ::v-deep .el-collapse-item__wrap{
    border-bottom: none;
  }
  .my-collapse ::v-deep .el-collapse-item__header{
    height: 68px;
    line-height: 68px;
  }
</style>


